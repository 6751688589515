<template>
  <div class="guide-container">
    <h1>
      How to transfer crypto from Trust Wallet to binance
    </h1>

    <br />
    <h2 class="second-colour">
      Overview
    </h2>

    <p>
      This guide covers how to transfer crypto from your crypto wallet to
      Binance. Bitcoin and the Trust Wallet app are used as examples, however
      most wallets and currencies follow the same process.
    </p>
    <p>
      If you don't have Trust Wallet installed or any crypto currency in your
      wallet, you can follow this guide here:
      <router-link
        to="/how-to-transfer-from-binance-to-another-wallet-address"
        target="_blank"
        >How to transfer from binance to another wallet address.</router-link
      >
    </p>
    <br />

    <h2 class="second-colour">
      Steps
    </h2>

    <ol class="padding-left-0">
      <!-- Start of ordered list -->

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li>
        Login to your Binance account
        <a href="https://binance.com/" target="_blank">here.</a>
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/1.webp"
          alt="Binance main home screen with login button"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Enter your email address or phone number. Click 'Next'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/2.webp"
          alt="Binance website login screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Enter your password. Click 'Log In'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/3.webp"
          alt="Binance website enter password screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Get Code' and then enter the verification code that was sent
        to your mobile / email address.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/4.webp"
          alt="Bianace verification enter code screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Wallet' -> 'Fiat And Spot'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/5.webp"
          alt="Binance main home screen showing bitcoin amount and menu dropdown"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Scroll down or search to find the coin you would like to deposit into
        your Binance account and click 'Deposit'. In the example here, BTC
        (Bitcoin) has been selected.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/6.webp"
          alt="Binance fiat and spot page showing list of coins to deposit"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Select Network'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/7.webp"
          alt="Binance Deposit Crypto screen with BTC selected"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Select the network. In the case of BTC (Bitcoin) it uses the BTC
        network.

        <p>
          Please choose the deposit network carefully and make sure that the
          selected network is the same as the network of the platform you are
          withdrawing funds from. If you select the wrong network, your funds
          might be lost and couldn’t be recovered. For detailed information of
          which network to select you can view point 4 in the Binance docs
          <a
            href="https://www.binance.com/en/support/faq/how-to-deposit-crypto-to-binance-115003764971"
            target="_blank"
            >here.</a
          >
        </p>
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/8.webp"
          alt="Binance Select Network popup for depositing crypto"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click 'Get Address'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/9.webp"
          alt="Binance website Deposit Cryto screen showing Get Address button"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Read the important bullets points and Minimum Deposit amount. Hover over
        the QR code icon to show your deposit address. You will scan this QR
        code later when using the Trust Wallet app.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/10.webp"
          alt="Binance website deposit crypto page showing QR code"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Open the Trust Wallet app on your phone. Click on 'Send'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/11.webp"
          alt="Trust Wallet app home screen on Android showing send button"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Bitcoin' or whichever crypto currency you would like to send
        to your Binance account.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/12a.webp"
          alt="Trust Wallet Send search coin screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on the 'Scan QR Code' button.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/13.webp"
          alt="Trust Wallet Send BTC screen with camera button"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        If your device requires permissions to access the camera, click 'Allow'.
        Then scan the QR code from your Binance account.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/14.webp"
          alt="Trust Wallet allow acces to photos and video popup"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        You should then see your Binance address at the top of the screen. You
        can now choose how much you would like to send to your Binance account.
        You can choose a specific amount or select 'Max' to send all currency in
        your wallet. Once you've chosen an amount click 'Continue'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/15.webp"
          alt="Trust Wallet Send BTC screen with amounts added"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        In the confirmation screen check the amount you're sending and the
        network fee. Here in the screenshot you can see a fee of £0.54 to send
        0.0008 BTC (about £15). After you've checked the amounts, click
        'Confirm'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/16.webp"
          alt="Trust Wallet transfer confirmation screen showing BTC and fee"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click the back arrow on the next screen.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/17a.webp"
          alt="Trust Wallet search - send screen with back arrow highlighted"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on the currency you just started a transfer of. Notice the '1'
        notification pip above the Bitcoin icon in this example.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/18.webp"
          alt="Trust Wallet home screen with Bitcoin notification highlighted"
        />
      </li>
      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on the 'Transfer' entry.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/19.webp"
          alt="Trust Wallet Bitcoin detail screen showing transfer line"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Notice the status as 'Pending'. Click on 'More Details'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/20.webp"
          alt="Trust Wallet transfer status pending screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Here the transfer is 'waiting for confirmations'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/21.webp"
          alt="Trust Wallet waiting for confirmations screen"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        After a few minutes if you go back to this page you will see an update
        to the number of confirmations.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/22.webp"
          alt="Trust wallet payment confirmation page showing confirmed count"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Go back to your Binance page and refresh. At the bottom you should see
        the status updated to 'Locked'. You can also see how many confirmations
        are needed for your transfer to be sold or transferred to fiat currency.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/23.webp"
          alt="Binance website Transfer Crypto page showing locked status"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        After a few minutes if you refresh you should see the status as
        'Completed'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/24.webp"
          alt="Binance website crypto deposit page showing completed status"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        To confirm the transfer in to your Binance account click on 'Wallet' ->
        'Fiat and Spot'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/25.webp"
          alt="Binance deposit crypto screen showing wallet menu and fiat and spot option"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Scroll down or search for the currency you transferred into Binance. You
        should see the updated amount once the confirmations have happened in
        your Trust Wallet app. Here you can see the new amount of 0.0008 BTC.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/26.webp"
          alt="Binance website fiat and spot screen showing update btc amount"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        To view the history of your deposits click on 'Wallet' -> 'Transaction
        History'.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/27.webp"
          alt="Binance website showing transaction history menu option"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        You should see the Status as 'Completed'. Click on the small arrow to
        the right.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/28.webp"
          alt="Binance transaction history page"
        />
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        The specific details of the transaction appear here.
        <br>
        <img
          class="margin-top-20px"
          src="@/assets/imgs/Guides/Binance/HowToTransferCryptoFromTrustwalletToBinance/29.webp"
          alt="Deposit details popup in binance"
        />
      </li>

      <!-- End of Steps ordered list -->
    </ol>

    <br />
    <br />
    <h2 class="second-colour">
      Crypto deposited successfully
    </h2>

    <p>
      Congratulations. You've now transferred currency from your crypto wallet
      to your Binance account.
    </p>

    <p>
      For more information on how you can transfer your crypto to your bank
      account, or from Binance to your crypto wallet. Check out these guides
      here:
    </p>
    <p>
      <router-link
        to="/how-do-i-withdraw-money-from-binance-to-my-bank-account-website-version"
        target="_blank"
        >How do I withdraw money from binance to my bank account</router-link
      >
    </p>

    <p>
      <router-link
        to="/how-to-transfer-from-binance-to-another-wallet-address"
        target="_blank"
        >How to transfer from binance to another wallet address</router-link
      >
    </p>
  </div>
</template>
